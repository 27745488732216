import AuthRepository from '@/repositories/general/auth-repository'

export default {
  methods: {
    logout () {
      this.$vs.loading()
      const params = { deviceToken: localStorage.getItem('deviceToken') }
      AuthRepository.logout(params)
        .then(response => {
          this.$store.commit('auth/CLEAR_USER_DATA')
        })
        .catch((e) => {
          console.log(e)
          this.notifyError('Terjadi kesalahan.')
        })
    }
  }
}
