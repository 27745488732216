<template>
    <div class="h-screen flex w-full">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">
            <h1 class="sm:mx-0 mx-4 mb-12 text-4xl">403 - Forbidden!</h1>
            <p class="sm:mx-0 mx-4 mb-4 sm:mb-16 md:w-4/5">Mohon maaf, Anda tidak dapat mengakses halaman ini karena tidak memiliki hak akses.</p>
            <vs-button size="large" to="/">Kembali ke Dashboard</vs-button>
            <vs-button class="mt-3" color="danger" size="large" type="flat" @click="logout">Logout</vs-button>
        </div>
    </div>
</template>

<script>
import authMixin from '@/utilities/mixins/auth-mixin'

export default {
  mixins: [authMixin],
  data () {
    return {}
  }
}
</script>
